.filterContainer {
  // position: absolute;
  // width: 150px;
  // height: 45px;
  // background-color: #F6F9FC;
  // z-index: 1000;
  // padding : 5px;
  // border-radius: 5px;
  // border:solid 2px rgba($color: #000000, $alpha: 0.1);
  // margin-top: 15px;
  .filter-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    .filterInput {
      height: 30px !important;
      padding-right: 35px;
    }
    .close-icon {
       margin-left: -30px;
       cursor: pointer;
    }
  }
}

.column-heading {
  // &:hover {
  //   .filter-icon {
  //     visibility: visible !important;
  //   }
  // }
  .filter-icon {
    // visibility: hidden;
    cursor: pointer;
  }
}
// custom styles for calendar
@import "react-big-calendar/lib/sass/styles";

.batch-display-form {
}

@mixin gradient {
  background: linear-gradient(0deg, #ededed, #ededed),
    linear-gradient(0deg, #e3e3e3, #e3e3e3);
}

.batch-name {
  height: 52px;
  width: 398px;
  left: 0px;
  top: 24px;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  @include gradient();
}

.batch-details {
  height: 220px;
  width: 398px;
  left: 0px;
  top: 256px;
  border-radius: 8px;
  padding: 16px;
  background: #f5f5f5;
  margin-bottom: 10px;
}

.gap {
  gap: 5px;
}

.progress-bar-timetable {
  padding: 16px 0px;
}

.timetable-heading {
  font-size: 28px !important;
  line-height: 40px !important;
  /* identical to box height, or 143% */
  color: #262626 !important;
  text-align: center;

  /* Grey/Grey 90 */
}

.timetable-next-btn {
  padding: 17px 16px;
  gap: 10px;
  height: 62px;
  background: #00b0f5;
  border-radius: 12px;
  color: #ffffff;
  margin-top: auto;
}

.plan-name-warning {
  align-items: flex-start;
  padding: 12px 24px;
  gap: 24px;

  // width: 746px;
  height: 88px;

  /* Warning/Warning 10 */

  background: #fff6e3;
  /* Warning/Warning 40 */

  border: 1px solid #ffd47f;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  /* Inside auto layout */
}

.change-plan-dropdown {
  cursor: pointer;
}

.new-chip {
  width: 48px;
  height: 24px;

  /* Positive/Positive 10 */
  background: #edf7f2;
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timetable-lecture-details-card-row {
  display: grid;
  column-gap: 10px;
  grid-auto-flow: column;
  grid-template-columns:
    minmax(120px, 1fr) minmax(80px, 0.5fr) repeat(2, minmax(90px, 1fr))
    repeat(3, minmax(120px, 1fr)) minmax(100px, 1fr) repeat(
      2,
      minmax(120px, 1fr)
    )
    minmax(60px, 1fr) repeat(2, minmax(120px, 1fr));
  // grid-auto-columns: minmax(40px, 1fr);
  align-items: center;
  &.no-actions {
    grid-template-columns:
      minmax(120px, 1fr) minmax(80px, 0.5fr) repeat(2, minmax(90px, 1fr))
      repeat(3, minmax(120px, 1fr)) minmax(100px, 1fr) repeat(
        2,
        minmax(120px, 1fr)
      )
      minmax(60px, 1fr) repeat(1, minmax(120px, 1fr));
  }
}
.timetable-lecture-details-card-row-without-msteams {
  @extend .timetable-lecture-details-card-row;
  grid-template-columns:
    minmax(120px, 1fr) minmax(80px, 0.5fr) repeat(2, minmax(90px, 1fr))
    repeat(3, minmax(120px, 1fr)) minmax(100px, 1fr) repeat(
      2,
      minmax(120px, 1fr)
    )
    repeat(2, minmax(120px, 1fr));

  &.no-actions {
    grid-template-columns:
      minmax(120px, 1fr) minmax(80px, 0.5fr) repeat(2, minmax(90px, 1fr))
      repeat(3, minmax(120px, 1fr)) minmax(100px, 1fr) repeat(
        2,
        minmax(120px, 1fr)
      )
      repeat(1, minmax(120px, 1fr));
  }
}
.timetable-lecture-details-card-row-view {
  display: grid;
  column-gap: 10px;
  grid-auto-flow: column;
  grid-template-columns:
    minmax(120px, 1fr) minmax(80px, 0.5fr) repeat(2, minmax(90px, 1fr))
    repeat(3, minmax(120px, 1fr)) minmax(100px, 1fr) repeat(
      2,
      minmax(120px, 1fr)
    )
    minmax(120px, 1fr) repeat(2, minmax(100px, 1fr));
  // grid-auto-columns: minmax(40px, 1fr);
  align-items: center;
  &.no-actions {
    grid-template-columns:
      minmax(120px, 1fr) minmax(80px, 0.5fr) repeat(2, minmax(90px, 1fr))
      repeat(3, minmax(120px, 1fr)) minmax(100px, 1fr) repeat(
        2,
        minmax(120px, 1fr)
      )
      minmax(120px, 1fr) repeat(1, minmax(100px, 1fr));
  }
}

.rbc-toolbar span:nth-child(1) {
  display: inline-block;
}
.rbc-day-slot .rbc-event {
  border: 1px solid white !important;
  // padding: 4px 8px !important;
}
.rbc-day-slot .rbc-event-content {
  font-size: 12px;
}
.rbc-allday-cell {
  // display: none !important;
  height: 0px !important;
}
.rbc-time-header-content {
  border: none;
}
.rbc-header {
  background-color: transparent;
  border: none;
  padding: 8px 0;
}
.rbc-header + .rbc-header {
  border-left: none;
}
.rbc-btn-group > button:nth-child(4) {
  display: none;
}
.rbc-btn-group {
  margin-right: 18px;
}

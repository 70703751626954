
.customCalendar{
    height: 650px;
    padding: 0 0 40px;
}

.rbc-toolbar button{
    border: 1px solid #00d0f1 !important;
    color: #00d0f1;
}

.rbc-toolbar button:hover,
.rbc-toolbar button:focus,
.rbc-toolbar button:active{
   background-color: #00d0f1 !important;
   color: #ffffff !important;
   
}

.rbc-day-bg{
    cursor: pointer;
}

.rbc-day-bg:hover{
    background-color: #00d0f1 !important;
    
    font-weight: 800 !important;
}

.rbc-date-cell{
    position: relative;
    cursor: pointer;
}

.rbc-date-cell a{
    
    // position: absolute;
    // left: 0;
    // width: 100% !important;
    // font-size: 18px;
}

.rbc-date-cell a:hover{
    
   background-color: #00d0f1 !important;
   color: #ffffff !important;
}

.rbc-active{
    background-color: #E6E7E8 !important;
    box-shadow: inset 2px 2px 8px #E6E7E8 !important;
    border-color: #00d0f1 !important;
    background-color: #00d0f1 !important;
    color: #ffffff !important;
    font-weight: 800;
}


.rbc-header{
    background-color: #00d0f1;
    color: #ffffff;
}

.rbc-toolbar-label{
    color: #abb5bd;
    font-size: 20px;
    font-weight: 800;
}
.rbc-toolbar span:nth-child(1){
    display: none;
}

.rbc-toolbar-label{
    text-align: left !important;
}

// .rbc-off-range-bg{
//     pointer-events: none;
// }

// .rbc-off-range{
//       pointer-events: none;
// }
// .rbc-btn-group{
//     display: none;
// }
// .rbc-day-bg{
//  border: 1px solid #00d0f1 !important;
// }

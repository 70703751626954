.permision_denied{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #b3b2b2;
    font-size: 35px;
    height: 82vh;
  }

.permission_lock_icon {
  font-size: 65px;
}

.permision_denid_Label{
    color: #b3b2b2;
    font-size: 15px;
    margin-top: 20px;
  }
  
.No_Data_Found_Label {
  margin-top: 20px;
}
.custom__checkbox__block {
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: center;

    .custom__checkbox__input {
        font-size: 2rem;
    }

    .custom__checkbox__input {
        appearance: none;
        background-color: transparent;
        margin: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #959495;
        border-radius: 0.15em;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .custom__checkbox__input::before {
        content: '';
        width: 10px;
        height: 10px;
        clip-path: polygon(94% 32%, 31% 88%, 4% 54%, 17% 42%, 34% 63%, 84% 18%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #fff;
    }

    .custom__checkbox__input:checked {
        background-color: #00b0f5 !important;
        border: none;
    }

    .custom__checkbox__input:checked::before {
        transform: scale(1);
        background-color: yellow
    }
}
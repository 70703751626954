.content_card {
  background-color: #FFF;
  border-radius: 12px;
  padding: 20px;
  margin: 20px;
  // flex-grow: 1;
}

.schedule_row {
  display: grid;
  grid-template-columns: 85px 1fr 2fr 1fr 1.5fr 1fr;
  column-gap: 15px;
  height: 40px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #262626;

  &.header {
    font-weight: 600;
  }

  >div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  >div:last-child {
    text-align: right;
  }
}

// .schedule_row>div {
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow: hidden;
// }

// .schedule_row>div:last-child {
//   text-align: right;
// }
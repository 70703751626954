.fixed_header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px !important;
}

.card_body{
    padding: 0;
}

.btn_refresh{
    width: 155px;
    height: 42px;
}

.number_val{
    height: 15px;
    width: 15px;
    background-color: red;
    color: #ffffff;
}

.refresh_properties{
    font-size: 15px;
    font-weight: 800;
}

.refresh_properties_title{
    font-size: 16px;
    font-weight: 800;
    color:#2dce89;
}
.custom__toggle__input:checked+span:before {
    background-color: #00b0f5 !important;
}

.custom__toggle__input:checked+span {
    border-color: #00b0f5 !important;
}

.custom__toggle__input:checked+span:after {
    color: #00b0f5 !important;
    padding-left: 4px !important;
}

.custom__toggle__input {
    background-color: #00b0f5 !important;
    transition: 0.5 ease-in-out !important;
}
.session_expire_info_page {
    height : 80vh;

    .inner-container {
        width : 50%;
        height : 50%;
        background-color:  White;
        text-align: center;

        h5{
            span {color: blue;
                cursor: pointer;
            }
        }
    }
}
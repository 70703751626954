.manage-qr-hdr {
    font-size: 20px;
    font-weight: 600;
    color: #0F0F0F;
}

.vh-100{
    height: 100vh;
}

.empty-list-qrIcon{
    height:72px;
    width:72px ;
}

.qr-modal-box {
    border: 1px solid #EDEDED;
    border-radius: 12px;
    margin: 24px 0px;
    padding: 16px;
}

.table-cell{
    font-size: 16px;
    font-weight: 400;
    color: #262626;
}

.view-qr{
    font-size: 14px;
    font-weight: 600;
    color: #008DC4;
    cursor: pointer;
}

.info-msg{
    font-size: 14px;
    font-weight: 400;
    color: #757575;
    margin-left: 10px;
}

.width-70{
    width: 70% !important;
}

.top-20{
    top: 20%;
}
.tableLessonPlan {
  display: block !important;
  overflow-x: auto !important;
  width: 100% !important;
}

.table-header {
  height: 48px !important;
}

.column-width-hours {
  max-width: 80px !important;
  padding: 0px 19px !important;
  /* line-height: 1.6 !important; */
}

.column-width-sequence {
  max-width: 80px !important;
  padding: 0px 9px !important;
}

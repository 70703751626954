@import url('https://fonts.cdnfonts.com/css/gilroy-bold');

.login__page__container {
  width: 100%;
  left: 0;
  right: 0;
  letter-spacing: 1.2px;
  font-family: 'Gilroy-Medium', sans-serif;
  background: #fff !important;
  height: 100vh;
  overflow-y: auto;
 

  .login__page__left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    padding: 0 20px;
    background-color: #00b0f5;
    border-radius: 0;
    margin: 0 !important;

    .login__banner__content {
      text-align: left;
      font-weight: bold;

      .login__banner__heading {
        font-size: 25px;
        color: #fff;
        line-height: 1.3;

        .login__banner__span {
          font-size: 18px;
          color: #fff;
        }
      }
    }

    .login__banner__image {
      margin-top: 66px;
      width: 100%;
    }
  }

  .resetpw__page__left {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 0 20px;
    border-radius: 0;
    margin: 0 !important;

    .resetpw__banner__image {
      margin: auto;
      margin-top: 66px;
      width: 70%;
    }

    .resetpw__banner__content {
      justify-content: center;
      margin-top: 20px;

      .resetpw__banner__heading {
        font-size: 25px;
        line-height: 1.3;

        .resetpw__banner__span {
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
}

.login__page__right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 100vh;
  background-color: #fff;
  border-radius: 0;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  


  .logo__brand__aakash__block {
    position: relative;
    margin-top: 20px;
    float: right;
    // top: 32px;
    margin-left: auto;
    margin-right: 50px;

    .brand__logo__aakash {
      width: 110px;
      height: 77.3px;
      object-fit: contain;
    }
  }

  .welcome__header__block {
    text-align: left;
    margin-bottom: 35px;

    .welcome__header {
      font-weight: bold;
      color: #414141;
      font-size: 23px;

      span {
        font-size: 28px;
        color: #00b0f5;
      }
    }

    .reset__header {
      font-weight: bold;
      color: #414141;
      font-size: 28px;
    }
  }

  .form_container {
    display: flex;
    justify-content: center;
  }

  .form__control {
    text-align: left;
    width: 65%;
    transition: all 0.5s;
    // margin-top: 100px;
    // height: 100%;
    // display: flex;

    @media screen and (max-width: 440px) {
      width: 90%;
    }

    @media screen and (max-width: 1500px) {
      width: 75%;
    }

    .login__input__label {
      color: #414141;
    }

    .login__input__box {
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 5px;

      Input {
        width: 100%;
        outline: none;
        box-shadow: none;
        // font-family: 'Gilroy-bold', sans-serif;
        // font-weight: bold;
        color: #00b0f5;
        letter-spacing: 0.5px;
        margin: 2px;

      }

      Input:valid {
        border: 1px solid #00b0f5;
      }

      Input:valid+Button {
        border: 1px solid #00b0f5;
      }

      .login__input__icons {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #a1aeb7;

        .login__input__eye__button {
          box-shadow: none;
          background-color: transparent;
          color: inherit;
          cursor: pointer;
          border: none;
        }
      }
    }

    .login__button {
      margin-top: 57px;
      width: 100%;
      background: #00b0f5;
      border: none;
      color: #fff;
      height: 50px;
      letter-spacing: inherit;
      margin-left: 0 !important;
    }

    .login__button_mt {
      // margin-top: 25px !important;
      width: 100%;
      background: #00b0f5;
      border: none;
      color: #fff;
      height: 50px;
      letter-spacing: inherit;
      margin-left: 0 !important;
    }

    .login__input__error {
      display: flex;
      justify-content: end;
      color: #f5365c;
      letter-spacing: normal;
      // font-family: 'Gilroy-bold', sans-serif;
      font-size: 12px;
      font-weight: 500;
      margin-top: 2px;
    }

    .login__input__left__error {
      display: flex;
      justify-content: start;
      color: #f5365c;
      letter-spacing: normal;
      // font-family: 'Gilroy-bold', sans-serif;
      font-size: 12px;
      font-weight: 500;
      margin-top: 2px;
    }

    .login__input__info {
      letter-spacing: normal;
      // font-family: 'Gilroy-bold', sans-serif;
      font-size: 12px;
      font-weight: 500;
      margin-top: 10px;
    }

    .login__input__note {
      color: #00b0f5 !important;
    }
  }
}

input.error {
  color: #f5365c !important;
  border: 1px solid #f5365c !important;
}


.or {
  position: relative;
  // width: 300px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 15px 0;
}

.or::before,
.or::after {
  position: absolute;
  width: 40%;
  height: 1px;
  top: 24px;
  background-color: #aaa;
  content: '';
}

.or::before {
  left: 0;
}

.or::after {
  right: 0;
}

.completed_step {
  background-color: #00b0f5;
  height: 2px;
  width: 48%;
}

.incomplete_step {
  background-color: #aaa;
  height: 2px;
  width: 48%;
}

.w_80 {
  width: 80%;
}

.user_id {
  // height: 14px;
  // margin: 15px 847px 22px 0px;
  // font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00b0f5;
}

.upperCase {
  text-transform: uppercase !important;
}

.formgot_password {
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00b0f5;
  text-align: center;
  cursor: pointer;
}

.resendText {
  color: #a1aeb7;
}
.resendBtn{
  cursor: pointer;
  color: #00b0f5;
}
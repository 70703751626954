.search-match-container{
    display: flex ;
	justify-content: space-between;
	position: relative;
}
// empty batch placeholder 
.center-item{
    display: flex;
    justify-content: center;
    align-items: center;
}
.empty-batch-placeholder{
    @extend .center-item;
    flex-direction: column;
    height: 100%;
    gap: 20px;
}
.folder-icon-wrapper{
    box-sizing: border-box;
    width: 120px;
    height: 120px;
    /* Grey */
    background: #E9ECEF;
    /* Grey 10 */
    border: 1px solid #E7E7E7;
    border-radius: 50%;
    /* Inside auto layout */
    @extend .center-item;
}

.folder-icon-wrapper svg{
    height: 40px !important;
    width: 40px !important;
}

// batch  search by name input 
.search-input{
    padding: 7px 8px;
    padding-left: 30px;
    height: 31px;
    font-size: 12px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    width: 100%;
    max-height: 15rem;
    min-width: 15rem;
    border: 1px solid rgb(0, 174, 239);
}
.search-input-wrapper>svg{
    position: absolute  ;
    top: 5.5px;
    // top: 0;
    bottom: 0;
    left: 5px;
    // margin: auto;
    cursor: pointer;
    z-index: 3;
}
.search-input-icon{
    background: url('../../../../../assets/img/icons/common/search.png') no-repeat scroll 7px 7px;
    padding-left:25px;
    background-size: 15px;
}
.student-details__chip {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 11px;
    gap: 6px;
    min-width: 99px;
    height: 25px;
    /* Grey */
    background: #e9ecef;
    /* grey 20 */
    border: 1px solid #cccccc;
    border-radius: 8px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 1.5px 1px;
    font-size: 15px;
}
.student-details__chip svg {
    cursor: pointer;
}
.filter-braces::after{
     content: ')';
}

.filter-braces::before{
    content: '(';
}
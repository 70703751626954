.table__card {
  overflow-x: scroll;
  //   min-width: 1200px;
}

::-webkit-scrollbar-thumb:horizontal {
  height: 4px;
  background: lightsteelblue;
  overflow: visible;
  border-radius: 4px;
}

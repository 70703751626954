.header_container {
  background-color: #002331;
  color: #FFFFFF;
  padding: 2rem 1rem;
}
.title_button_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.display_details_container {
  display: grid;
  column-gap: 20px;
  row-gap: 1rem;
  /* grid-auto-flow: column; */
  /* grid-auto-columns: minmax(140px, max-content); */
  grid-template-columns: repeat(auto-fill, minmax(140px, max-content));
  grid-auto-rows: max-content;
}
.view_container {
  padding: 1rem
}
.grid_view_container {
  display: grid;
  column-gap: 1.5rem;
  row-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: repeat(auto-fill, minmax(425px, max-content)); */
  grid-auto-rows: max-content;
}
.list_view_container {
  display: flex;
  flex-flow: column;
}
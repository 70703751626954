.backButton{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #11cdef;
    width: 30px;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    color:#ffffff;
    font-size:18px;
    border-radius: 0px 100% 100% 0;
}

.uploadingHeading{
  display: flex;
  align-items: center;
  padding-left: 13px;
}

.madatoryField{
  color:red;
}
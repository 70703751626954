.headerStyle {
  gap: 12px;
  box-shadow: 0px 8px 8px 0px #0000000d;
  padding-bottom: 18px;
  padding-left: 10px;
}

.container {
  padding: 20px;
  min-height: 80vh;
  background-color: #eee;
}

.cursorPointer {
  cursor: pointer;
}

.table {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  height: 100%;
}

.materialMenuWrapper {
  min-width: 150px;
  min-height: 70px;
  max-width: fit-content;
}
.materialMenu {
  border: none;
}
.materialMenu td {
  padding: 0 0.5rem;
}
.materialMenuDesc {
  /* max-width: 300px; */
  padding: 0.7rem 0;
}

.editSapInput {
  width: 150px;
}

// Dropdown.scss

.dropdown {
    position: relative;
    display: inline-block;
    margin-bottom: 0px !important;

    .dropdown-list {
        .dropdown-item {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            input {
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                width: 20px;
                height: 20px;
                border: 2px solid #00B0F5;
                background-color: #ffffff;
                border-radius: 4px;
                outline: none;
                cursor: pointer;
                position: relative;

                &:checked {
                    background-color: #00B0F5;
                    border: 2px solid #00B0F5;

                    &::before {
                        opacity: 1;
                    }

                    &:disabled{
                        background-color: #B0B0B0 !important;
                        border: 2px solid #B0B0B0;
                        &:hover {
                            border: 2px solid #B0B0B0;
                        }
                    }
                }

                &:disabled{
                    background-color: #B0B0B0;
                    border: 2psx solid #B0B0B0;
                    &:hover{
                        border: 2psx solid #B0B0B0;
                    }
                }

                &::before {
                    content: url('../../../../../../../assets/checked.png');
                    height: 15px;
                    color: white;
                    font-size: 16px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                }
            }

            label {
                margin-bottom: 0;
            }
        }
    }

    // .main-container {
    //     .red-checkbox {
    //       display: flex;
    //       align-items: center;

    //       input {
    //         appearance: none;
    //         -webkit-appearance: none;
    //         -moz-appearance: none;
    //         width: 20px;
    //         height: 20px;
    //         border: 2px solid #dadada;
    //         background-color: #dadada;
    //         border-radius: 4px;
    //         outline: none;
    //         cursor: pointer;
    //         position: relative;
    //         &:hover{
    //           border: 2px solid red;;
    //         }
    //         &:checked {
    //           background-color: red;
    //           border: 2px solid red;
    //           &::before {
    //             opacity: 1;
    //           }
    //         }
    //         &::before {
    //           content: "✔";
    //           color: white;
    //           font-size: 16px;
    //           position: absolute;
    //           top: 50%;
    //           left: 50%;
    //           transform: translate(-50%, -50%);
    //           opacity: 0;
    //         }
    //       }
    //     }
    //   }
    &-label {
        cursor: pointer;
        padding: 10px;
        border: 1px solid #cccccc;
        border-radius: 4px;
        min-width: 166px;
        min-height: 28px;
        font-size: 12px;
        font-weight: 900;
        line-height: 16px;
        background-color: #fff;
         &:hover{
            background-color: #00B0F5;
            color:#ffffff
         }
    }

    &-list {
        position: absolute;
        top: 100%;
        left: 0;
        border: 1px solid #cccccc;
        background: #fff;
        z-index: 1;
        box-shadow: 0px 4px 32px 0px #3D467014;
        border-radius: 8px;
        padding: 10px;
    }

    // &-item {
    //   display: flex;
    //   align-items: center;
    //   padding: 5px;
    //   font-size: 14px;
    //   font-weight: 400;
    // }

    &-item {
        display: flex;
        align-items: center;
        padding: 5px;
        font-size: 14px;
        font-weight: 400;

        // input[type="checkbox"] {
        //     display: none; // Hide the actual checkbox input

        //     &:checked + label {
        //       &::before {
        //         background-color: #00B0F5;
        //         border-color: #00B0F5;
        //       }
        //     }
        //   }

        label {
            position: relative;
            padding-left: 25px;
            cursor: pointer;

            //   &::before {
            //     content: "";
            //     position: absolute;
            //     top: 50%;
            //     left: 0;
            //     transform: translateY(-50%);
            //     width: 16px;
            //     height: 16px;
            //     border: 2px solid #00B0F5;
            //     background-color: #fff;
            //   }

            //   // Checked state styles
            //   input[type="checkbox"]:checked + &::before {
            //     background-color: #00B0F5;
            //     border-color: #00B0F5;
            //   }

            //   // Disabled state styles
            //   input[type="checkbox"]:disabled + & {
            //     color: #888; // Grey color for the label when it's disabled
            //   }

            //   // Checked and disabled state styles
            //   input[type="checkbox"]:checked:disabled + &::before {
            //     background-color: #ccc; // Greyed background for checked and disabled state
            //     border-color: #ccc;
            //   }

            // Checked state styles
            // input[type="checkbox"]:checked + &::before {
            //     background-color: lightblue;
            //     border-color: lightblue;
            // }

            // // Disabled state styles
            // input[type="checkbox"]:disabled + & {
            //     color: #888; // Grey color for the label when it's disabled
            //     cursor: not-allowed;
            // }

            // // Checked and disabled state styles
            // input[type="checkbox"]:checked:disabled + &::before {
            //     background-color: #ccc; // Greyed background for checked and disabled state
            //     border-color: #ccc;
            // }
        }
    }
}

.auto-min-width{
    min-width: auto !important;
}

.cmpr-success-svg svg{
    height:42px;
    width: 42px;
}
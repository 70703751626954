@mixin report-category-row {
    color: #00B0F5;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    font-weight: 500;
    padding: 10px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.1);
  }
  
  .report-category-table {
    @include report-category-row;
  }
  
  .report-category-table:hover {
    @include report-category-row;
    background-color: rgba(128, 128, 128, 0.1);
  }
  .center-item{
    display: flex;
    justify-content: center;
    align-items: center;
}
  .empty-report-placeholder{
    @extend .center-item;
    flex-direction: column;
    height: 100%;
    gap: 20px;
}
.folder-icon-wrapper{
    box-sizing: border-box;
    width: 120px;
    height: 120px;
    /* Grey */
    background: #E9ECEF;
    /* Grey 10 */
    border: 1px solid #E7E7E7;
    border-radius: 50%;
    /* Inside auto layout */
    @extend .center-item;
}
  
  
.user_profile {
  position: fixed;
  right: 20px;
  width: 36px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00b0f5 !important;
  border-radius: 4px;
  font-size: 14px;
  background: #ffffff;
  font-weight: bold;
  letter-spacing: 1px;
}

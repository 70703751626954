.loader_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 160px);

  .container {
    position: relative;
  }

  .loader_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
  }

  .loader_wrapper {
    height: 150px;
    width: 150px;
  }

  .loader {
    width: 100%;
    height: 100%;
    border: 7px solid #f8f9fe;
    border-radius: 100%;
    border-left-color: #00aeef;
    border-right-color: #00aeef;
    animation: rotate 5s linear infinite;
  }

  .loader_inner {
    border-left-color: #00aeef;
    border-right-color: #00aeef;
    animation-duration: 2.5s;
  }

  @keyframes rotate {
    0% {
      transform: scale(1) rotate(360deg);
    }
    50% {
      transform: scale(0.8) rotate(-360deg);
    }
    100% {
      transform: scale(1) rotate(360deg);
    }
  }
}

.student_service_container{

    .card-body{
        .student_service_border::after{
    content: '';
    position: absolute;
    background: #00d0f1;
    top:0;
    left: 0;
    border-radius: 100%;
    height: 1px;
    width: 10%;
}



.student_service_border::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 4px;
    background-color: #00d0f1;
    left: 0;
    top: 0;
}



.student_service_border_bottom{
    position: absolute;
    height: 1px;
    width: 30%;
    background: #00d0f1;;
    bottom: 0;
    left: 0;
    border-radius: 0 0 100%;
}

.student_service_header{
    padding: 0 !important;
    // border-bottom: 1px solid #00d0f1 !important;
        
}
    }
    .container-fluid{
        padding-left: 0 !important;
        padding-right: 0 !important;
        .student_service_card{
            margin-bottom: 0 !important;

            .ticket_border{
                border: 1px solid #00d0f1;
            }
        }
    }
}
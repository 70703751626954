.custom_model .modal{
  overflow-y: hidden !important;
}
.model_title{
  border-bottom: 1px solid #e9ecef;
}

.model_body{
  font-weight: 900 !important;
  max-height:450px;
  overflow-y: auto;
  overflow-x: hidden;
}

.model_footer{
  border-top: 1px solid #e9ecef;
  padding:5px;
}

.document_proceess_tag_header {
    max-width: 450px !important;
    @media screen and (max-width : 1200px) {
        flex-direction: column !important;
        max-width: 320px !important;
    }
    div {
        @media screen and (max-width : 1200px) {
           margin-bottom: 10px;       
           padding-left:  0 !important;          
        }
        h3 {
            @media screen and (max-width : 1200px) {
                min-width: 100px !important;
                width: 100% !important;    
                padding-left:  0 !important;  
                margin-left: 0 !important;
            }
        }

        input {
            @media screen and (max-width : 1200px) {
                min-width: 190px !important;
                width: 100% !important;      
            }
        }
    }
}
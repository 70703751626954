.No_Data_Found{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #b3b2b2;
  font-size: 35px;
  height: 150px;
}
.No_Data_Found_Label{
  color: #b3b2b2;
  font-size: 15px;
  margin-top: 20px;
}

.export_button{
    color: #2e7d31;
    background-color: transparent;
    border-color: #2e7d31;
    height:38px;
    min-width:150px;
    box-shadow: none;
    font-weight: 700;
    padding: 4px 8px;
    // box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    transition: all 0.2s ease-in-out;
}

.export_excel_text{
    width: 97px;
    height: 14px;
    margin: 4px 0 4px 8px;
    //font-family: Gilroy;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2e7d31;
}

//
.export_button:hover{
    color: #2e7d31;
    background-color: transparent;
    border-color: #2e7d31;
}

.export__icon {
    padding-bottom: 1px;
}
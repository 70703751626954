// Bootstrap functions
@import "bootstrap/functions";
@import "bootstrap/mixins";

// Argon functions
@import "custom/functions";
@import "custom/variables";
@import "bootstrap/variables";

// Argon mixins
@import "custom/mixins";

// Bootstrap components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

// Argon components
@import "custom/components";
@import "custom/utilities";
@import "custom/vendors";

// React differences
@import "react/react-differences";


// Custom CSS

// Common CSS classes

.floatRight{
    float: right;
}
.floatLeft{
    float: left;
}
.tdInput{
    height:40px
}
.custom_select__control {
    height: 100%;
    border: 0px !important;
  }
  .custom-toggle input:checked +.activeToggle:before {
    background: #00b0f5;
    transform: translateX(48px) !important;
}

.custom-toggle.v3 input:checked +.activeToggle:before {
    background: #00b0f5;
    transform: translateX(26px) !important;
}

.tdSelect {
    height: 40px !important;
    // width: 100% !important;
}
.select2.select2-container {
    height: 38px !important;
    .select2-selection.select2-selection--single {
        height: 38px !important;
        width: 100% !important;
        padding: 0.480rem .75rem 0.350 1.25rem!important;
        font-size: 0.88rem;
    }
}
.white-breakSpace{
    white-space: break-spaces !important;
}
.tableLayout{table-layout: fixed}
.select2-selection__rendered{
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
@media(max-width:800px){
    .tableLayout{table-layout: unset !important}
}
.Toastify__close-button{
    margin-left: 10px !important;
}
// Common CSS classes Ends



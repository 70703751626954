// @import url('https://fonts.cdnfonts.com/css/gilroy-bold');
@font-face {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Bold'), url('./assets/fonts/Gilroy/Gilroy-Bold.woff') format('woff');
}


@font-face {
  font-family: 'Gilroy-Heavy';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Heavy'), url('./assets/fonts/Gilroy/Gilroy-Heavy.woff') format('woff');
}


@font-face {
  font-family: 'Gilroy-Light';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Light'), url('./assets/fonts/Gilroy/Gilroy-Light.woff') format('woff');
}


@font-face {
  font-family: 'Gilroy-Medium';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Medium'), url('./assets/fonts/Gilroy/Gilroy-Medium.woff') format('woff');
}


@font-face {
  font-family: 'Gilroy-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Regular'), url('./assets/fonts/Gilroy/Gilroy-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-SemiBold'), url('./assets/fonts/Gilroy/Gilroy-SemiBold.ttf') format('woff');
}

/** use this for debugging scrollbar for windows **/

// ::-webkit-scrollbar {
//   width: 16px;
//   height: 16px;
// }
 
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }
 
// ::-webkit-scrollbar-thumb {
//   background-color: darkgrey;
//   outline: 1px solid slategrey;
// }

#concessionModalRoot {
  top: inherit !important
}

body {
  font-family: 'Gilroy-Regular', sans-serif;
  letter-spacing: 0.5px;
}

button {
  margin-right: 0 !important;
  margin-left: 12px !important;
}

.navbar-search .input-group {
  border-radius: 10px !important;
}

.btn {
  margin-right: 0 !important;
  margin-left: 12px !important;
}

#questionTable {

  //overflow: auto;
  tr:hover {
    background-color: #f6f9fc;
    box-shadow: #f5f0f0 0px 2px 2px;
    transition: all 0.2s ease-out 0s;
  }
}

.btn-info {
  background-color: #00aeef !important;
  border-color: #00aeef !important;
}

.fa-user-shield,
.fa-medium,
.fa-briefcase,
.fa-folder,
.fa-tasks,
.fa-school,
.fa-calculator,
.fa-school,
.fa-file-alt,
.fa-user-graduate,
.fa-calendar-alt,
.fa-shield-alt,
.fa-users {
  //color:#00AEEF !important;
}

.ps__thumb-y {
  background-color: lightsteelblue !important;
}

.bg-info {
  background-color: #00aeef !important;
}

//.table-responsive{
//
//  overflow: scroll;
//  height: 55vh !important;
//}
// Custome css for table
.table-responsive-layout {
  overflow: scroll;

  table {
    min-width: 1250px !important;

    th,
    td {
      min-width: 210px !important;
      max-width: 210px;
    }
  }
}

.table-role-responsive-layout {
  overflow: scroll;
  overflow-x: auto;

  table {
    min-width: 1250px !important;

    th,
    td {
      min-width: 210px !important;
      max-width: 210px;
    }
  }
}

.table-responsive-layout.no_custom_col_width {
  overflow: scroll;

  table {
    min-width: 1250px !important;

    th,
    td {
      min-width: unset !important;
      max-width: unset !important;
    }
  }
}

.td-w-100,
.th-w-100 {
  max-width: none !important;
}

.field-info {
  font-size: 10px;
  font-weight: 900;
  margin-left: 3px;
  margin-top: 5px;
}

.hiddenRow {
  padding: 0 !important;
}

.details {
  max-height: 0px;
  overflow-y: hidden;
  overflow-x: hidden;
  transition: all 700ms ease-in;
}

.details-height-auto {
  max-height: 500px;
  transition: all 700ms ease-in;
}

.epmr-inner-details-header {
  font-weight: 900;
}

@media (min-width: 576px) {
  .modal-dialog {
    // max-width: 700px ;
  }
}

.select2-selection--single {
  overflow: hidden !important;
}

.btn:focus {
  //box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

// styles for Select Dropdown Arrow SVG
.css-6q0nyr-Svg {
  width: 15px !important;
  height: 15px !important;
}

// multiselectDropdown Button - Active
.css-52s2pl {
  border: 2px solid #2684ff !important;
}

.css-4u3a4u:focus {
  border: 1px solid #2684ff;
}

.css-2sqt8e:focus {
  border: 1px solid #2684ff;
}

.css-1vr111p-option :focus,
.css-1vr111p-option :hover {
  background: #2684ff;
}

.main_header {
  width: 100vw !important;
  z-index: 50 !important;
  position: fixed !important;
  top: 0px !important;
  display: block !important;
}

.main_content {
  margin-top: 95px !important;
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  //margin-bottom: 65px !important;
}

.card-footer,
.footer {
  //margin-left: 30px !important;
  //margin-right: 30px !important;
}

::-moz-selection {
  /* Code for Firefox */
  background-color: #00aeef !important;
  color: white !important;
}

::selection {
  background-color: #00aeef !important;
  color: white !important;
}

//body{
//  font-family:Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
//}
//.footer{
//  padding-bottom : 0px !important;
//}
.avatar-sm {
  background: rgba(36, 124, 166, 0.2) !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: lightsteelblue transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: lightsteelblue;
  border-radius: 20px;
  border: 3px solid #e9ecef;
}

input:not([disabled]):focus,
input:not([disabled]):hover {
  color: #00aeef;
  border-color: #00aeef !important;
}

.form-control {
  color: #00aeef !important;
  //height:40px !important
}

.form-control:focus {
  border-color: #00aeef !important;
}

.css-hlqyuu-control:focus,
.css-2b097c-container:focus {
  border-color: #00aeef !important;
}

// input[type=checkbox], input[type=checkbox]:checked {
//   background-color:red !important;
// }

textarea[disabled],
input[disabled] {
  //height:45px !important;
  //  cursor: not-allowed;
  color: #00b0f5 !important;
  font-size: 14px;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
   background-color: rgba(0, 176, 245, 0.05) !important;
  // border: 1px solid #dee2e6;
  border-radius: 4px;

  // border: none !important;
  :hover {
    border: 0 !important;
    border-radius: 4px !important;
  }
}

.react-datepicker__input-container {
  input[type='text'][disabled] {
    background-color: #EDEDED !important;
    color: #0F0F0F !important;
  }
}
.react-datepicker__input-container  .new-calender{
  input[type='text'][disabled] {
    background-color: #EDEDED !important;
    
  }
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  //display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 46px;
  height: 22px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  margin-bottom: 0 !important;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 17px;
  height: 17px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 40px;
}

.select2.select2-container {
  height: 40px !important;

  :focus {
    border: 2px solid #00aeef !important;
    border-radius: 4px !important;
  }

  .select2-selection.select2-selection--single {
    height: 40px !important;
    width: 100% !important;
    padding: 0.48rem 0.75rem 0.35 1.25rem !important;
    font-size: 0.88rem;
  }
}

// .Select.is-disabled > .Select-control {
//   cursor: not-allowed;
//   color: #aaa !important;
// }

// .Select-placeholder {
//   color: black !important;
// }

// .Select.is-disabled {
//   color: #aaa !important;
// }


///

.dropdown-new .hovered {
  display: none;
}

.card-header {

  h2,
  h3 {
    color: #00aeef !important;
  }
}

.dropdown-new:hover .hovered {
  display: initial;
}

//.react-datepicker__triangle::after{
//  border-bottom-color : #fff !important;
//}
//.react-datepicker__triangle::before{
//  border-bottom-color: #f0f0f0 !important;
//}
//.react-datepicker {
//  border:1px solid #f0f0f0 !important;
//  border-radius: 10px !important;
//}
//.react-datepicker__header{
//  background-color: #fff !important;
//  border-bottom:0 !important;
//  padding:0 !important;
//  //padding:8px 0px 0px 0px !important;
//}
//.react-datepicker__header:not(.react-datepicker__header--has-time-select){
//  border-top-right-radius :10px !important;
//  border-top-left-radius :10px !important;
//}
//.react-datepicker__day--selected{
//  background-color:#fff !important;
//  color:#000 !important;
//  border: 1px solid #000 !important;
//  border-radius :100% !important;
//
//}
//.react-datepicker__day:hover{
//    border-radius :100px !important;
//}
//.react-datepicker__month{
//  margin:10px 0px 0px 0px !important;
//}
//.react-datepicker__day-names{
//  border-bottom:1px solid #f0f0f0 !important;
//  font-weight:550 !important;
//}
//

.tooltip {
  width: 300px;
  top: -1px !important;
  left: -1px !important;
}

.tooltip__content {
  text-align: left;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker-popper {
  padding: 0 !important;
  z-index: 1400 !important;
}

.react-datepicker__month-container {
  border: 1px solid #dee2e6 !important;
  border-radius: 8px !important;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  justify-content: space-between !important;
}

input {
  //color:#00AEEF !important;
  //height:40px;
}

//
//input[placeholder] {
//  color: #00AEEF !important;
//}
th .form-group{
  margin-bottom: 0;
}
th .css-2azqpw-placeholder{
  font-size: 12px;
  text-transform: capitalize;
}
.search-button {
  width: 149px;
  height: 43px;
  margin: 43px 20px 0 67px;
  padding: 15px 43px 14px 42px;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: #00b0f5;
}

.reset-button {
  min-width: 117px;
  height: 43px;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: #fafafa;
}
.reset-button-primary{
  @extend .reset-button ;
  background-color: rgb(0, 176, 245);
  color : #fff
}

.normal-button {
  // padding: 14px 12px 15px;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: #00b0f5;
}

.upload-button {
  // padding: 14px 22px 15px;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #00b0f5;
  background-color: #fff !important;
}

.btn-padding {
  padding: 8px 48px;
}

.vbtn-padding{
  padding: 8px 20px;
}

.goback-button {
  background-color: #fff !important;
  border: solid 1px #00b0f5;
  &:hover{
    color: #00b0f5;
  }
}

.br-8{
  border-radius: 8px;
}

.addNewCourseDetailsButton {
  margin: 0 auto;
  display: block;
}

//textarea[disabled]{
//  color: #00b0f5 !important;
//}

// Select2 Styling
.select2-selection__rendered {
  color: #00aeef !important;
}

// .select2.select2-container .select2-selection.select2-selection--single {
//   border: 1px solid red;
// }

// .select2-selection.select2-selection--single:has(.select2-selection__rendered){


//       border: 1px solid red;



// }

input:not(:placeholder-shown) {
  // border: 1px solid #00aeef;
  color: #00aeef !important;
}

.form-control.is-valid {
  border: 1px solid #00aeef;
  color: #00aeef;
}

.form-control.is-invalid {
  border: 1px solid #f5365c;
}

.bs-popover-auto {
  margin-top: 17px !important;
}

.sideNav-toggler-tab-screen {
  display: none !important;

  @media screen and (min-width: 576px) and (max-width: 1200px) {
    display: block !important;
  }

  @media screen and (max-width: 575px) {
    display: block !important;
  }
}

.navbar-vertical .navbar-brand-img,
.navbar-vertical .navbar-brand>img {
  max-width: 100%;
  max-height: 4rem !important;
  padding: 5px !important;
  margin: 2px !important;
}


.btn.custom-btn:disabled {
  background-color: #a1aeb7 !important;
  border: 1px solid #a1aeb7 !important;
  outline: none !important;
  box-shadow: none !important;
  cursor: no-drop !important;
}

.navbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.navbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;


  button {
    margin-left: auto !important;
    margin-right: 10px !important;
  }
}

.card .table.feetable td,
.card .table.feetable th {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ellipse-text {
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.styles-for-multiSelect {
  &>div {
    margin: 2px;
  }
  .css-ik6y5r{
    height:200px !important;
    // overflow: scroll;
    text-align: left;
  }
}

.css-ik6y5r {
  text-align: left;
  position: fixed;
  background: #fff;
  margin: 0 auto;
}

.BTNbgChange {
  background: #00b0f5;
}

.BTNbgChange.disabled {
  background: #c3cacf !important;
  color: #000000 !important;
}

h3.card-header-black, .black-grey {
  color: #414141 !important;
}

.batch-common-input-style .form-group .form-control-label {
  font-size: 18px;
  font-weight: 500;
}

.batch-display-field {
  font-size: 18px;
  font-weight: 500;
  color: #414141;
}

.MuiList-root {
  max-height: 15rem;
}

.MuiAutocomplete-inputRoot {
  padding: 1px !important;
  font-size: 12px !important;
}

.card .card-header .permission-tabs div h3.tab-active {
  color: #fff !important;
}

.card .card-header .permission-tabs div h3.tab-inactive {
  color: #000 !important;
}

.remove-container-top-space {
  margin-top: -16px;
}

.global-font-size {
  font-size: 14px;
}

.button_remove_left_margin {
  margin-left: 0 !important;
}

.large-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.regular-large {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.text-regular-large {
  font-size: 16px !important;
}

.regular-large-bold {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.regular-bolder {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
}

.regular-bold {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.regular-body {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.regular-small {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: .5px;
}

.semi-bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.large-semi-bold {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.large-bolder {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
}

.large-bold {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.tiny-semi-bold {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.color-dark {
  color: #0F0F0F;
}

.color-black {
  color: #000;
}

.color-light {
  color: #FFFFFF
}

.color-primary {
  color: #00B0F5;
}

.align-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.heading-4 {
  //styleName: Heading/H4;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.heading-3 {
  //styleName: Heading/H4;
font-size: 16px;
font-weight: 600;
line-height: 28px;
letter-spacing: 0em;
text-align: left;
}
.tableWrap {
  // max-height: 24rem;
  overflow: auto;
}
.cardPopper{
  // position: relative;
  // bottom: 2rem;
  width: 100%;
}
.cardPopper .form-control-label{
 color: white;
}
.cardPopper .form-group{
  margin-bottom: 0;
 }
.heading-2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
}

.cursor {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

.btn-transparent.btn, .btn-transparent.btn:disabled {
  background: none !important;
  box-shadow: none !important;
  border: none !important;

  &:hover {
    border: none !important;
  }
}

.ellipsis-text-container {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-left-0 {
  margin-left: 0 !important;
}

.label-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.label-color {
  color: #262626;
}

.color-black-60 {
  color: #45464E;
}
.color-grey-50 {
 color: #AFAFAF; 
}
.color-grey-60 {
  color: #757575;
}

.color-grey-54 {
  color: #545454;
}
.color-grey-90 {
  color: #262626;
}

.color-grey-80 {
  color: #333333;
}

.color-aqua-80 {
  color: #004662;
}

.color-aqua-40 {
  color: #33C0F7;
}

.color-red-40 {
  color: #E7685A
}
.color-red-60 {
  color: #B32306
}
.color-purple-50 {
  color: #6E42E5 ;
}
.border-purple{
  border: 1px solid #6E42E5

}

.bg-red-60 {
  background-color: #B32306 !important;
  border: 1px solid #B32306 !important;
  box-shadow: none;
  color: #fff;
}

.bg-light {
  background-color: #fff;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-20{
  margin-top: 20px;
}
 
.lh-10{
  line-height: 10px;
}

.block-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scroll-overlay {
  overflow-y: hidden;

  &:hover {
    overflow: overlay
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #E9ECEF;
  }
}

.form-control:disabled, .form-control[readonly] {
  background-color: rgba(0, 176, 245, 0.05) ;
}
.react-datepicker-wrapper{
  width: 100% !important;
}

.data-grid-edit-cell-container {
  padding: 0 10px;
  width: 100%;
}

.no-ml-btn {
  margin-left: 0 !important;
}

.outline-btn {
  &:hover {
    background-color: #00B0F5 !important;
    border: 1px solid #00B0F5 !important;
    color: #fff !important;
  }
  &:disabled {
    color: #fff !important;
  }
}

.bg-light-blue.btn {
  &:disabled {
    background-color : rgba(0, 176, 245, 0.05) !important;
    border: 1px solid #00AEEF !important;
  }
}


.disable-btn-clss {
  &:disabled {
    color: #fff !important;
  }
}


.mui-table-pagination {
  div .react-select__indicator-separator {
    display: none; /* Hide the vertical separator line */
  }
}

.bg-red-40 {
  background-color : #E7685A !important;
  border: 1px solid #E7685A !important;
}


.muiMultiSelectWithCheckboxes {
  div {
    button {
      margin-left: 0 !important;
    }
  }
}

.gap-xs {
  gap: 3px
}

.gap-md {
  gap: 8px
}

.gap-lg {
  gap: 16px
}

.gap-xl {
  gap: 24px
}

// this class is used for wrapping screen cards used in stock and inventory module 
// please check impact before updated this class
.screen-cards-container {
  padding: 24px 20px;
  display: grid;
  column-gap: 24px;
  row-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
}
.text-bold{
  font-family: Gilroy-Medium ;
  font-weight: 600,
}

.custom-toggle input:disabled + .custom-toggle-slider {
  cursor: not-allowed !important;
}

.border-radius-sm {
  border-radius: 4px;
}
.border-radius-md {
  border-radius: 8px;
}
.border-radius-lg {
  border-radius: 12px;
}


.newToggle {
  align-items: center !important;
  label {
    width: 2rem;
    height: 0.75rem;

    input:disabled + span {
      cursor: not-allowed !important;
    }

    input:checked + span {
      background-color: #CCEFFD !important;
      border: 1px solid #CCEFFD !important;
      &::before {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: #00B0F5 !important;
        color: #00B0F5;
        bottom: -5px;
        left: -2px;
      }
    }

    span {
      background-color: #757575 !important;
      width:40px !important;
      &::before {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: #B0B0B0 !important;
        color: #B0B0B0;
        bottom: -5px;
        left: -2px;
      }
    }
  }
}

.min-page-height {
  min-height: calc(100vh - 79px);
}

@media (max-width: 1030px) {
  .hide-on-mobile-tablet {
    display: none !important;
  }
}

@media only screen and (min-width: 600px){
  .attendance-gate-tab-margin {
    margin-top: -15px;
  }
}

@media only screen and (max-width: 599px) {
  .attendance-gate-mobile-margin {
    margin-top: -52px;
  }
}
@media only screen and (max-width: 599px) {
  .attendance-gate-mobile-bottom-margin {
    margin-bottom: -35px;
  }
}
@media only screen and (min-width: 600px){
  .attendance-gate-tab-bottom-margin {
    margin-bottom:0px;
  }
}

.deposit-slip .dropdown {
    position: relative;
    display: flex;
    margin-bottom: 10px !important;
    flex-direction: column;

    &-input {
        width: 100%;
        padding: 8px 16px;
        border: 1px solid #757575;
        border-radius: 8px;
        position: relative;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    &-arrow {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        pointer-events: none;

        &.open {
            transform: translateY(-50%) rotate(180deg);
        }
    }

    &-list {
        position: relative;
        top: 100%;
        left: 0;
        z-index: 1;
        width: 100%;
        max-height: 150px;
        overflow-y: auto;
        border: none !important;
        border-top: none;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        //   display: none;
        background-color: #fff;
    }

    &-option {
        padding: 8px 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;

        &:hover {
            background-color: #f0f0f0;
        }

        input {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 20px;
            height: 20px;
            border: 2px solid #00B0F5;
            background-color: #ffffff;
            border-radius: 4px;
            outline: none;
            cursor: pointer;
            position: relative;

            &:checked {
                background-color: #00B0F5;
                border: 2px solid #00B0F5;

                &::before {
                    opacity: 1;
                }

                &:disabled {
                    background-color: #B0B0B0 !important;
                    border: 2px solid #B0B0B0;

                    &:hover {
                        border: 2px solid #B0B0B0;
                    }
                }
            }

            &:disabled {
                background-color: #B0B0B0;
                border: 2psx solid #B0B0B0;

                &:hover {
                    border: 2psx solid #B0B0B0;
                }
            }

            &::before {
                content: url('../../../../../../../assets/checked.png');
                height: 15px;
                color: white;
                font-size: 16px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
            }
        }

        label {
            margin-bottom: 0;
            margin-left: 12px;
        }
    }

    &-checkbox-label {
        margin-left: 8px;
    }
}

.finalize-charges-button{
    &:hover {
        color :#ffffff !important;
        background-color: #00B0F5;
        border-color: #00B0F5 !important;
    }
}
.student_calendar{
    .student_calendar_container{
     padding-left: 0  !important;
     padding-right: 0  !important;
}
    }
   
.student_calendar_top{
    position: absolute;
    border-radius: 100%;
    top: 0;
    left: 0;
    height: 4px;
    width: 20%;
}

.student_calendar_left{
    position: absolute;
    height: 100%;
    width: 4px;
    left: 0;
    top: 0;
}

.student_calendar_bottom{
    bottom: 0;
    height: 4px;
    width: 100%;
    position: absolute;
    left: 0;
    // border-radius: 0 0 100%;
}


.row-padding{
    padding: 10px;
    min-height: 500px;
}

.button-Loader{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
}

.data-font-size{
    font-size: 15px;
}


.header_search{
    position: relative;
    height: 40px;
    width: 250px;
    border-radius: 30px;
    overflow: hidden;    
}

.header_search Input{
  height: 100%;
  width: 100%;
  border-radius: 30px;
}

.search_icon{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 50px;
    width: 50px;
    background-color: #11cdef;
    color: #ffffff;
    cursor: pointer;

}

.heading_color{
    color:#525f7f;
    font-weight: 700;
}
// .bubble_animation{
// position: absolute;
// height: 50px;
// width: 50px;
// border-radius: 100%;
// user-select: none;
// // background-color: #E6E7E8;
// box-shadow: inset 4px 4px 23px #E6E7E8 !important;
// }

// .bubble_animation:nth-child(1){
    
//     right: 10%;    
//     animation: bubble 6s linear infinite;
// }

// .bubble_animation:nth-child(2){
    
//     right: 40%;
//     // transition-delay: 3s;
//     animation: bubble 6s linear infinite;
//     animation-delay: 1s;
// }

// .bubble_animation:nth-child(3){
    
//     right: 70%;
//     // transition-delay: 5s;
//       animation: bubble 6s linear infinite;
//        animation-delay: 2s;
// }

// .bubble_animation:nth-child(4){
    
//     right: 90%;
//     // transition-delay: 7s;
//     animation: bubble 6s linear infinite;
//     animation-delay:3s ;
// }

// @keyframes bubble {
//    0%,100%{
//        transform:translateY(0px,20px);
       
//    }
//   50%{
//       transform:translateY(400px);
//   }
// }
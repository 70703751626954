.header_div {
  background-color: #002331;
  height: 205px;
  width: 100%;
  color: #FFFFFF;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
}

.buttonCustomStyles {
  margin-left: 0 !important;
}
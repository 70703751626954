.table_th_padding{
  padding: 12px 4px;
}

.table_th_sub{
  border-top: unset !important;
  width: 100px;
}

.table_th_border_bottom{
  border-bottom: unset !important;
}

.label_background{
  background: #a9a9a9;
  color: #ffffff;
  font-weight: 700;
  border-radius: 10px 10px 0 0;
  margin: 0 !important;
}


.customCheckBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .form_control {
    font-size: 2rem;
  }

  input[type='checkbox'] {
    appearance: none;
    background-color: #ffffff;
    margin: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #959495;
    border-radius: 0.15em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  input[type='checkbox']::before {
    content: '';
    width: 10px;
    height: 10px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em green;

  }

  input[type='checkbox']:checked::before {
    transform: scale(1);
  }

}

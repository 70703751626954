.mode_container {
  // background-color: #fff;

  .body_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15);
    border: solid 1px rgba(0, 0, 0, 0.05);
  }
  .icon {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 46px;
    font-weight: 500;
    color: #00aeef;
    background-color: #fff;
    border: 4px solid rgba(0, 176, 245, 0.3);
    margin-bottom: 18px;
  }

  .confirmation_message {
    font-size: 24px;
    font-weight: 500;
    color: #32325d;
    margin-bottom: 0;
  }

  .signOut_message {
    font-size: 14px;
    font-weight: 500;
    color: #525f7f;
  }

  .btn_yes,
  .btn_no {
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    background-color: #f8f9fe;
    color: #414141;
    outline: none;
    border: none;
  }
}

.mode_container {
  // background-color: #fff;

  .body_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15);
    border: solid 1px rgba(0, 0, 0, 0.05);
  }

  .secondary_body_content {
    // display: flex;
    // justify-content: left;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15);
    border: solid 1px rgba(0, 0, 0, 0.05);
    word-wrap: break-word;
  }

  .icon {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 46px;
    font-weight: 500;
    color: #00aeef;
    background-color: #fff;
    border: 4px solid rgba(0, 176, 245, 0.3);
    margin-bottom: 18px;
  }

  .secondary_icon {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 46px;
    font-weight: 500;
    font-weight: 500;
    color: #414141;
    background-color: #fff;
    border: 2px solid #414141;
    margin-bottom: 18px;
  }

  .backPrompt_icon {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 27px;
    font-weight: 500;
    font-weight: 500;
    color: #414141;
    background-color: #fff;
    border: 2px solid #414141;
    margin-bottom: 18px;
  }

  .confirmation_message {
    font-size: 24px;
    // font-weight: 500;
    color: #000000;
    margin-bottom: 0;
  }

  .secondary_confirmation_message {
    font-size: 24px;
    // font-weight: 500;
    color: #000;
    margin-bottom: 0;
  }

  .confirmation_message_no_icon {
    font-size: 24px;
    font-weight: 500;
    color: #00b0f5;
    margin-bottom: 0;
  }

  .signOut_message {
    font-size: 14px;
    font-weight: 500;
    color: #525f7f;
    // text-align: center;
  }

  .secondary_signOut_message {
    font-size: 14px;
    font-weight: 900;
    color: #525f7f;
    // text-align: center;
  }

  .btn_yes,
  .btn_no {
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    background-color: #f8f9fe;
    color: #414141;
    outline: none;
    border: none;
  }

  .btn_ok {
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    background-color: #00b0f5;
    color: #fff;
  }

  .btn_delete_yes {
    padding: 10px 16px;
    gap: 10px;
    width: 180px;
    height: 44px;
    background: #CC5F5F;
    border-radius: 12px;
    color: #fff;
  }

  .btn_delete_no {
    padding: 10px 16px;
    gap: 10px;
    width: 180px;
    height: 44px;
    border: 1px solid #414141;
    border-radius: 12px;
    width: 180px;
  }

  .btn_back {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #00B0F5;
    border: 2px solid #00B0F5;
    border-radius: 8px;
    width: 180px;
  }

  .btn_confirm {
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    background-color: #00B0F5;
    color: #FFF;
    outline: none;
    border: none;
  }

  .checkbox {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
}
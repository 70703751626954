.logout_container {
  padding: 10px 60px 46px 60px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  .logout_icon {
    width: 36px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00b0f5;
    border-radius: 4px;
    color: #ffffff;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 14px;
  }

  .logout_name {
    font-size: 14px;
    color: #414141;
    margin: 0;
    font-weight: bold;
    line-height: 16px;
  }

  .logout_email {
    font-size: 14px;
    font-weight: 500;
    color: #a1aeb7;
    margin: 0;
    line-height: 16px;
  }

  .logout_image {
    position: absolute;
    padding: 10px 20px;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: solid 1px #ebebeb;
    background-color: #fff;
    display: flex;
    cursor: pointer;
    border-radius: 0px 0px 20px 20px;
    p {
      margin: 0;
      padding-left: 10px;
      color: #414141;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.MuiToolbar-root {
	display: flex;
	justify-content: center;
	align-items: center;
}

.MuiToolbar-root p {
	margin-bottom: 0px;
}

.MuiDataGrid-columnSeparator {
	display: none !important;
}

.MuiDataGrid-root {
	border-radius: 0px !important;
}

.MuiDataGrid-main {
	height: 185px;
}

.MuiDataGrid-root .MuiDataGrid-cell {
	border-bottom: none !important;
}

.MuiDataGrid-root {
	border: none !important;
	border-top: 1px solid rgba(224, 224, 224, 1) !important;
}
.header-bar {
    // height: 68px;
    // padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: left;

  }
  
  .page-container {
    margin: 20px;
    padding: 20px;
    background-color: #FFF;
    border-radius: 12px;
  }
.image-placeholder{
    text-align: center;
}
.image-placeholder svg{
    width: 35px;
}

.sub-heading{
    font-size: 14px;
    text-align: center;
}
.button-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.file-exist-container{
    display: flex;
    align-items: center;
    // margin-left: 10px;
    gap : 10px;
    color: black;
    font-weight: 500;     
    & svg.pdf-icon{
        width: 18px;
    }
}

.header-column{
    border-bottom:1px solid #E3E3E3;
    font-size:14px;
    color : black;
    font-weight: 600;
    line-height: 20px;
    font-family: Gilroy-Medium;
    padding-bottom: 10px;
    width: fit-content;
    min-width: 100%;
  }

  .add-material-table{
    height: 45px;
    display: grid;
    // grid-template-columns : .7fr .7fr .4fr .4fr .4fr .5fr .5fr;
    grid-template-columns: minmax(200px, .7fr) minmax(200px, .7fr) minmax(200px, .4fr)minmax(100px, .4fr)minmax(100px, .4fr)minmax(100px, .5fr)minmax(100px, .5fr);
    gap:10px;
    margin-top: 10px;
    align-items: center;
    font-size: 14px;
}
.issue-stock-misc{
    display: grid;
    // grid-template-columns : .7fr .7fr .4fr .4fr .4fr .5fr .5fr;
    grid-template-columns: minmax(115px, .4fr) minmax(235px, .4fr) minmax(140px, .4fr)minmax(200px, .4fr)minmax(95px, .3fr)minmax(85px, .3fr) minmax(170px, .3fr) minmax(70px, .3fr);
    gap:10px;
    margin-top: 10px;
    align-items: center;
    font-size: 14px;
    height: 42px;
}

.add-barcode-dlp{
    display: grid;
    grid-template-columns: minmax(80px, 110px) minmax(300px, .4fr) minmax(100px, .3fr) 165px auto;
    gap:10px;
    margin-top: 10px;
    align-items: center;
    font-size: 14px;
    height: 42px;
}

.consumption-history-table{
    display: grid;
    grid-template-columns: minmax(200px, .8fr) minmax(200px, .7fr) minmax(100px, .4fr) minmax(100px, .4fr);
    gap:10px;
    margin-top: 10px;
    align-items: center;
    font-size: 14px;
}
  
.table-border-material{
    border: 1px solid #CBCBCB;
    padding: 10px;
    border-radius: 8px;
    margin: 15px 0;
}

.barcode-consumption-history svg{
    width: 25px;
    height: 25px;
}

.warning-svg svg{
    width: 24px;
}
.a-h2{
// font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 30px;
// line-height: 12px;
/* identical to box height, or 133% */
text-align: center;
/* Grey/Grey 90 */
color: #262626;
/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 1;
}











// stokes 
//a-left
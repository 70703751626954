.fileName{
    font-weight: 600;
    font-size: 14px;
}

.file_name{
    color: #11cdef;
}

.final_status{
    font-weight: 500;
    padding-left: 15px;
}
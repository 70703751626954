.hd-header {
    background-color: #CCEFFD;
    padding: 24px 20px;
    margin: -15px 0px 20px 0px;

    h5 {
        color: #004662;
        font-size: 28px;
        margin-bottom: 15px;
        font-weight: 600;
    }

    div>input{
        width:30%;
    }
}

.hd-container {
    margin:20px;

    .hd-card-wrapper {
        background-color: #fff;
        padding: 24px;
        border-radius: 12px;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    }

    .card-title {
        color: #0F0F0F;
        font-size: 20px;
        font-weight: 600;
    }

    .hr-style{
        width:90%;
        margin:0px;
        padding:5px 0px;
      }

    .card-link {
        margin-top: 20px;

        .MuiTypography-root {
            font-weight: 700;
            font-size: 18px;
            color: rgb(0, 176, 245);
        }
    }
    .search{
        div{
            min-height: 0px !important;
        }
    }
    .backgound-h{
        height: 750px;
      }
    
    .search-icon {
        position: absolute;
        top:50%;
        right: 10px;
        transform: translate(0px, -10px);
    }
}

.depositSlip-header {
    background-color: #FFFFFF;
    box-shadow: 0px 8px 8px 0px #0000000D;
    padding: 12px 26px;
    color: #45464E;
    font-weight: 700;
    margin-top: -14px;

    svg {
        margin-right: 10px;
    }
}

.depositslip-form {
    .card {
        margin: 25px;
        border-radius: 12px;
    }

    .deposit-slip-notification {
        background: rgb(245, 245, 245);
        border: 1px solid rgb(203, 203, 203);
        border-radius: 12px;
        padding: 16px;

        h6 {
            font-size: 16px;
            color: rgb(38, 38, 38);
            margin-bottom: 10px;
            font-weight: 700;
        }

        .checkbox-container {
            color: rgb(38, 38, 38);

            label {
                span {
                    font-size: 14px;
                }
            }
        }
    }

    .deposit-btn {
        margin-top: 20px;

        button {
            border: 1px solid rgb(0, 176, 245);
            background-color: rgb(0, 176, 245);
            color: rgb(255, 255, 255);
            border-radius: 12px;
            padding: 14px 18px;
            margin-left: 0 !important;
        }
    }

    .edit-btn {
        margin-top: 20px;

        button {
            border: 1px solid rgb(0, 176, 245);
            background-color: rgb(255, 255, 255);
            color: rgb(0, 176, 245);
            border-radius: 12px;
            padding: 17px 16px;
        }

        p {
            font-size: 14px;
            margin: 0px 20px;
            color: #262626;
        }
    }

    .form-control-label {
        color: #262626 !important;
        font-size: 12px !important;
    }

    input:not(:placeholder-shown) {
        color: #262626 !important;
        background-color: #fff !important;

    }
}

.house-bank-container{
    background-color: #F5F5F5;
    border-radius: 8px;
    padding: 18px 20px 0px 18px;

    p{
        margin-bottom: 6px;
        font-size: 12px;
    }

    h6{
        font-size: 16px;
        color: #0F0F0F;
        font-weight: 600;
    }

    .col-md-4{
        margin-bottom: 20px;
    }
}

.house-bank-container:not(:last-child){
    margin-bottom: 25px;
}

.search-container input{
    padding-right:34px!important;
}
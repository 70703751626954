// // @import url('http://fonts.cdnfonts.com/css/gilroy-bold');

// .homepage__container {
//   width: 100%;
//   height: calc(100vh - 146px);
//   padding: 0 !important;

//   .homepage__user__row {
//     margin: 0;
//     height: 100%;
//     .homepage_user_col {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       padding: 0;
//       margin: 0;
//     }
//     .homepage__card__rows {
//       display: flex;
//       justify-content: center;
//       height: auto;
//       border-radius: 0;
//       margin-bottom: 0;
//       box-shadow: none;
//       padding: 40px;
//       flex-direction: column;
//       align-items: center;

//       .homepage__name__label {
//         color: #414141;
//         font-size: 32px;
//         font-weight: 500;
//         margin-bottom: 22px;
//       }

//       .homepage__welcome__title {
//         font-weight: bold;
//         font-size: 50px;
//         font-weight: bold;
//         color: #00b0f5;
//         line-height: 45px;
//       }

//       .homepage__date {
//         font-size: 24px;
//         font-weight: 500;
//         color: #414141;
//         margin-top: 14px;
//       }
//     }
//   }

//   .homepage_card_container {
//     padding: 40px;
//   }

//   .homepage__feature__heading {
//     font-size: 27px;
//     margin: 20px !important;
//   }
// }

.homepageContainer {
  padding: 20px;
  min-height: calc(100vh - 79px);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  // border: 1px solid red;
}

.homepage__card {
  background-color: #FFF;
  padding: 16px 20px;
  border-radius: 8px;
}

.homepage__count-card {
  padding: 16px 20px;
  border: 1px solid #E2E2E2;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 158px;
  max-width: 250px;
}

.homepage__notifications-container {
  max-height: calc(100vh - 79px);
  width: 420px;
  position: sticky;
  top: 79px;
}
.primary_filled_button {
  border-radius: 8px;
  background-color: #00B0F5;
  font-size: 16px;
  padding: 10px 16px;
  font-weight: 600;
  color: #FFFFFF;
  letter-spacing: 1px;
  cursor: pointer;
}
.danger_filled_button {
  border-radius: 8px;
  background-color: #DC2804;
  font-size: 16px;
  padding: 10px 16px;
  font-weight: 600;
  color: #FFFFFF;
  letter-spacing: 1px;
  cursor: pointer;
}
.link_button {
  color: #00B0F5;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 1px;
}
.button_remove_left_margin {
  margin-left: 0 !important;
}

.customContainer{
    padding: 10px !important;  
   .customCardBody{
    //   height: 70vh;
   // overflow: auto;
       padding: 10px !important;
       .card{
           .card-body{
               .card{
                   .card{
                       margin-bottom: 40px !important;
                       .card-body{
                           .container{
                               .container{
                                   padding: 20px !important;
                                   .card{
                                       margin-bottom: 40px !important;
                                       .card-body{
                                           .container{
                                               padding: 0 !important;
                                               .card{
                                                   margin-bottom: 0 !important;
                                               }
                                           }
                                       }
                                   }
                               }
                           }
                       }
                   }
               }
           }
       }
   }
   .card{      
      
          .card-body{         
              .card{
                  .card-body{ 
                    .col{
                        .card{
                            margin-top: 0 !important;
                            
                        }
                    }
                      .card-body{
                          .card{
                              margin-bottom: 40px !important;
                          }
                      }
                      .container{
                       padding: 0 !important;
                       margin: 0 !important;
                       width: 100% !important;
                       max-width: 100% !important;
                       .card{
                           margin-bottom: 0 !important;
                           .card{
                               margin-bottom: 40px !important;
                           }
                       }
                  }
                  }
              }
          }
         }

    .container-fluid{
         .card{
          margin-bottom: 0 !important;
         }

        margin-bottom: 0 !important;
        padding: 0 !important;
        margin-top: 0 !important;
    }
}


.fixedHeader{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px !important;

    h2{
        font-weight: 800;
    }
    div{
        div{

            display: flex;
            span{
                padding-left: 10px;
                font-size: 15px;
                font-weight: 600;
            }
            h4{
                font-weight: 800;
            }
        }
    }
}


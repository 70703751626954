.align-end{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.input-height{
    height: 44px;
    margin-top: 6px;
}

.customLabel{
    background-color: #e9ecef;
    width: 100%;
    min-height: 38px;
    padding: 10px;
    border-radius: 3px;
    margin-top: 8px;
}

.customContainer {
     padding:0; 
     margin:0;
     max-width: 100% !important;
    // min-width: 1275px;
}
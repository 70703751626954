.drag_area {
  position: relative;
  border: 2px dashed #dae1ed;
  height: 350px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fafcfd;
  margin: auto;
}

.drag_area.active {
  border: 2px solid #fff;
}

.drag_drop_text {
  font-size: 16px;
  font-weight: 700;
  // color: #00b0f5;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // width: 30%;
  text-align: center;
}

.drag_drop_secondary_text {
  font-size: 14px;
  color: #a1aeb7;
  text-align: center;
}

.drag_area_icon_area {
  height : 125px;
  img {
    max-height: 116px;
  }
  .drag_area_icon {
    font-size: 100px;
    color: #c1c1c1;
  }

  .drag_area_icon_success {
    font-size: 100px;
    color: green;
  }
}

.drag_progressor {
  max-height: 116px;
  max-width: 116px;
}


.drag_area button {
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 700;
  border: none;
  outline: none;
  background: #fff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 27px !important;
}

.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.customProgressBar {
  width: 60%;
  height: 18px;
}

.removeImage {
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 20px;
  color:red
}

.madatoryField {
  color: red;
}